// import { Call } from '@mui/icons-material';
import { SidebarItemsType } from '../../types/sidebar';
import {
  Inbox as InboxIcon,
  Event as EventNoteIcon,
  TaskAlt as TaskAltIcon,
  Group as GroupIcon,
  // ViewColumn as ViewColumnIcon,
  TrendingUp as TrendingUpIcon,
  Numbers,
} from '@mui/icons-material';
import { Briefcase, Calendar, PhoneIncoming } from 'react-feather';

const pagesSection = [
  {
    href: '/crm/leads',
    title: 'Leads',
    icon: GroupIcon,
  },
  {
    href: '/crm/contacts',
    title: 'Contacts',
    icon: GroupIcon,
  },
  {
    href: '/crm/inbox',
    icon: InboxIcon,
    title: 'Inbox',
  },
  {
    href: '/crm/tasks',
    title: 'Tasks',
    icon: TaskAltIcon,
  },
  {
    href: '/projects',
    icon: Briefcase,
    title: 'Projects',
  },
  {
    href: '/servicing',
    icon: Briefcase,
    title: 'Servicing',
  },
  {
    href: '/crm/appointments',
    icon: Calendar,
    title: 'Appointments',
  },
  {
    href: '/crm/calendar',
    icon: EventNoteIcon,
    title: 'Calendar',
  },
  {
    href: '/crm/calls',
    icon: PhoneIncoming,
    title: 'Incoming Calls',
    children: [
      {
        href: '/crm/calls/missed-calls',
        title: 'Missed Calls',
      },
      {
        href: '/crm/calls/voicemails',
        title: 'Voicemails',
      },
    ],
  },
  {
    href: 'crm/analytics',
    icon: TrendingUpIcon,
    title: 'Analytics',
    children: [
      {
        href: '/crm/analytics/task',
        title: 'Task',
      },
      {
        href: '/crm/dashboard/appointment',
        title: 'Appointment',
      },
    ],
  },
] as SidebarItemsType[];

const adminSection = [
  {
    href: '/admin/phone-numbers',
    title: 'Phone Numbers',
    icon: Numbers,
    children: [
      {
        href: '/admin/phone-numbers/active',
        title: 'Active Numbers',
        permissions: ['admin:twilio:view-purchased-phone-numbers'],
      },
      {
        href: '/admin/phone-numbers/buy',
        title: 'Buy a Number',
        permissions: ['admin:twilio:view-available-phone-numbers'],
      },
    ],
  },
] as unknown as SidebarItemsType[];
// const elementsSection = [
//   {
//     href: '/components',
//     icon: Grid,
//     title: 'Components',
//     children: [
//       {
//         href: '/components/alerts',
//         title: 'Alerts',
//       },
//       {
//         href: '/components/accordion',
//         title: 'Accordion',
//       },
//       {
//         href: '/components/avatars',
//         title: 'Avatars',
//       },
//       {
//         href: '/components/badges',
//         title: 'Badges',
//       },
//       {
//         href: '/components/buttons',
//         title: 'Buttons',
//       },
//       {
//         href: '/components/cards',
//         title: 'Cards',
//       },
//       {
//         href: '/components/chips',
//         title: 'Chips',
//       },
//       {
//         href: '/components/dialogs',
//         title: 'Dialogs',
//       },
//       {
//         href: '/components/lists',
//         title: 'Lists',
//       },
//       {
//         href: '/components/menus',
//         title: 'Menus',
//       },
//       {
//         href: '/components/pagination',
//         title: 'Pagination',
//       },
//       {
//         href: '/components/progress',
//         title: 'Progress',
//       },
//       {
//         href: '/components/snackbars',
//         title: 'Snackbars',
//       },
//       {
//         href: '/components/tooltips',
//         title: 'Tooltips',
//       },
//     ],
//   },
//   {
//     href: '/charts',
//     icon: PieChart,
//     title: 'Charts',
//     children: [
//       {
//         href: '/charts/chartjs',
//         title: 'Chart.js',
//       },
//       {
//         href: '/charts/apexcharts',
//         title: 'ApexCharts',
//       },
//     ],
//   },
//   {
//     href: '/forms',
//     icon: CheckSquare,
//     title: 'Forms',
//     children: [
//       {
//         href: '/forms/pickers',
//         title: 'Pickers',
//       },
//       {
//         href: '/forms/selection-controls',
//         title: 'Selection Controls',
//       },
//       {
//         href: '/forms/selects',
//         title: 'Selects',
//       },
//       {
//         href: '/forms/text-fields',
//         title: 'Text Fields',
//       },
//       {
//         href: '/forms/editors',
//         title: 'Editors',
//       },
//       {
//         href: '/forms/formik',
//         title: 'Formik',
//       },
//     ],
//   },
//   {
//     href: '/tables',
//     icon: List,
//     title: 'Tables',
//     children: [
//       {
//         href: '/tables/simple-table',
//         title: 'Simple Table',
//       },
//       {
//         href: '/tables/advanced-table',
//         title: 'Advanced Table',
//       },
//       {
//         href: '/tables/data-grid',
//         title: 'Data Grid',
//       },
//       {
//         href: '/tables/advance-search-grid',
//         title: 'Advance Search Grid',
//       },
//     ],
//   },
//   {
//     href: '/icons',
//     icon: Heart,
//     title: 'Icons',
//     children: [
//       {
//         href: '/icons/material-icons',
//         title: 'Material Icons',
//       },
//       {
//         href: '/icons/feather-icons',
//         title: 'Feather Icons',
//       },
//     ],
//   },
//   {
//     href: '/maps',
//     icon: Map,
//     title: 'Maps',
//     children: [
//       {
//         href: '/maps/google-maps',
//         title: 'Google Maps',
//       },
//       {
//         href: '/maps/vector-maps',
//         title: 'Vector Maps',
//       },
//     ],
//   },
// ] as SidebarItemsType[];

// const docsSection = [
//   {
//     href: '/documentation/welcome',
//     icon: BookOpen,
//     title: 'Documentation',
//   },
//   {
//     href: '/changelog',
//     icon: List,
//     title: 'Changelog',
//     badge: 'v4.4.1',
//   },
// ] as SidebarItemsType[];
const navItems = [
  {
    title: 'Pages',
    pages: pagesSection,
  },
  ...(process.env.NODE_ENV !== 'production'
    ? [
        {
          title: 'Admin Section',
          pages: adminSection,
        },
      ]
    : []),
  // {
  //   title: 'Elements',
  //   pages: elementsSection,
  // },
  // {
  //   title: 'Mira Pro',
  //   pages: docsSection,
  // },
];

export default navItems;
