export enum DateFormatType {
  Standard = 'YYYY-MM-DD',
  StandardWithTime = 'YYYY-MM-DD HH:MM:SS',
  ShortMonthWithTime = 'MMM DD, YYYY h:mm A',
  DayOfWeek = 'Day',
  ShortDate = 'MM/DD/YYYY',
  LongDate = 'MMMM DD, YYYY',
  LongDateWithTime = 'LONG_DATE_WITH_TIME',
  ISOWithTimezone = 'ISO_WITH_TIMEZONE', // New format
}

export interface DateFormatterProps {
  date: string | number | Date;
  dateFormatType: DateFormatType;
}

export const DateFormatter = (props: DateFormatterProps) => {
  const { date: dateProps, dateFormatType } = props;

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatLongDateWithTime = (date: Date) => {
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${month} ${day}, ${year} at ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formatLongDate = (date: Date) => {
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const formatDateTime = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  };

  const formatDateToDay = (date: Date) => {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
  };

  const formatShortDate = (date: Date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const formatISOWithTimezone = (date: Date) => {
    return date.toISOString(); // Use built-in toISOString() method
  };

  const prepareDate = (date: string | number | Date) => {
    if (typeof date === 'string' && !isNaN(Number(date))) {
      return new Date(Number(date)); // Handle numeric string as timestamp
    }
    return new Date(date); // Handle normal strings or Date objects
  };

  const preparedDate = prepareDate(dateProps);

  switch (dateFormatType) {
    case DateFormatType.Standard:
      return formatDate(preparedDate);
    case DateFormatType.StandardWithTime:
      return formatDateTime(preparedDate);
    case DateFormatType.ShortMonthWithTime:
      return formatLongDateWithTime(preparedDate);
    case DateFormatType.DayOfWeek:
      return formatDateToDay(preparedDate);
    case DateFormatType.ShortDate:
      return formatShortDate(preparedDate);
    case DateFormatType.LongDate:
      return formatLongDate(preparedDate);
    case DateFormatType.LongDateWithTime:
      return formatLongDateWithTime(preparedDate);
    case DateFormatType.ISOWithTimezone:
      return formatISOWithTimezone(preparedDate);
    default:
      throw new Error(`Unsupported date format: ${dateFormatType}`);
  }
};
