import { SidebarItemsType } from '../../types/sidebar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import { UserCheck } from 'react-feather';

const userPagesSection = [
  {
    href: '/crm/user-profile/user/profile',
    title: 'Profile',
    icon: AccountCircleIcon,
  },
] as SidebarItemsType[];

const adminPagesSection = [
  {
    href: '/crm/user-profile/admin/company-management',
    icon: BusinessIcon,
    title: 'Company Management',
  },
  {
    href: '/crm/user-profile/admin/users',
    title: 'User management',
    icon: GroupOutlinedIcon,
  },
  {
    href: '/crm/user-profile/admin/roles-and-permissions',
    title: 'Roles and permissions',
    icon: UserCheck,
  },
] as SidebarItemsType[];

export const navItemsUser = [
  {
    title: 'Your Preferences',
    pages: userPagesSection,
  },
];

export const navItemsAdmin = [
  {
    title: 'Admin Preferences',
    pages: adminPagesSection,
  },
];
