import * as React from 'react';
import { FieldProps, FormikTouched } from 'formik';
import { TextField, createFilterOptions, TextFieldProps, SxProps, Theme } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Icon } from 'react-feather';
import { SvgIconComponent } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export type FormikAutocompleteOptionTypes = {
  id: number | string;
  value?: string;
  label: string;
};

type FormikAutoCompleteProps<T> = FieldProps & {
  label?: string | boolean;
  options: FormikAutocompleteOptionTypes[];
  touched: FormikTouched<T>;
  errors: T;
  values: T;
  disabled?: boolean;
  loading?: boolean;
  PopupIcon?: Icon | SvgIconComponent;
  forcePopupIcon?: boolean;
  sx?: SxProps<Theme>;
};

interface StringKeyedObject {
  [key: string]: string | Object | Array<Object>;
}

/**
 * A custom autocomplete component for use with Formik.
 *
 * @component
 * @param {FormikAutoCompleteProps<any>} props - The component props.
 * @param {any} props.textFieldProps - Additional props to pass to the TextField component.
 * @param {FieldProps<any>} props.field - The Formik field props.
 * @param {FormikProps<any>} props.form - The Formik form props.
 * @param {string} props.label - The label for the autocomplete field.
 * @param {Array<any>} props.options - The options for the autocomplete.
 * @param {boolean} props.isLoading - Indicates whether the autocomplete is currently loading.
 * @param {boolean} props.touched - Indicates whether the field has been touched.
 * @param {Object} props.errors - The errors object from Formik.
 * @param {Object} props.values - The values object from Formik.
 * @param {Function} props.setFieldValue - The function to set the field value in Formik.
 * @returns {JSX.Element} The rendered FormikAutoComplete component.
 */
const FormikAutoComplete: React.FC<FormikAutoCompleteProps<FieldProps & StringKeyedObject>> = ({
  textFieldProps,
  field,
  form,
  label = false,
  options,
  isLoading,
  touched,
  errors,
  values,
  setFieldValue,
  disabled = false,
  loading = false,
  PopupIcon = ArrowDropDownIcon,
  forcePopupIcon = true,
  sx,
  ...props
}: FormikAutoCompleteProps<FieldProps & StringKeyedObject> & {
  textFieldProps?: TextFieldProps;
  isLoading?: boolean;
  setFieldValue?: (value: string | Object | Array<Object>) => void;
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });

  return (
    <Autocomplete
      disabled={disabled}
      {...props}
      {...field}
      fullWidth
      filterOptions={filterOptions}
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id || value.id === ''}
      loading={loading}
      value={field.value ?? null}
      onChange={(_, value) => {
        form.setFieldValue(field.name, value);
        if (setFieldValue) {
          setFieldValue(value);
        }
      }}
      renderOption={(optionProps, option: FormikAutocompleteOptionTypes) => (
        <li {...optionProps} key={option.id}>
          {option.label}
        </li>
      )}
      size="small"
      sx={sx}
      popupIcon={<PopupIcon />}
      forcePopupIcon={forcePopupIcon}
      renderInput={inputProps => (
        <>
          <TextField
            {...inputProps}
            name={field.name}
            label={label}
            key={field.value?.id}
            helperText={touched[field.name] && (errors[field.name] as React.ReactNode)}
            error={Boolean(touched[field.name] && errors[field.name])}
            InputProps={{
              ...inputProps.InputProps,
            }}
            size="small"
            placeholder="Select or type to search..."
            {...textFieldProps}
          />
        </>
      )}
    />
  );
};

export default FormikAutoComplete;
