import React from 'react';
import { Typography, Divider as MuiDivider, List, Card, CardContent, Box } from '@mui/material';
import { spacing } from '@mui/system';
import styled from '@emotion/styled';
import { navItemsUser as userItems, navItemsAdmin as adminItems } from '../../../layouts/UserProfileItems';
import SidebarNavSection, { Title as SideNavSectionTitle } from '../../../../components/sidebar/SidebarNavSection';
import { SidebarContext, SidebarContextTypes } from '../../../../contexts/sidebar/SidebarContext';
import { Badge, Item, Title as SidebarNavListItemTitle } from '../../../../components/sidebar/SidebarNavListItem';

const Divider = styled(MuiDivider)(spacing);

const Items = styled.div`
  padding-bottom: ${props => props.theme.spacing(2.5)};
`;

function UserSettings() {
  const CustomSideNavSectionTitle = styled(SideNavSectionTitle)`
    color: #6f6f6f;
    padding-top: 0;
    padding-left: 0;
    text-transform: capitalize;
    opacity: 1;
  `;

  const CustomItem = styled(Item)`
    padding-left: 0.625rem;
    border-radius: 0.313rem;
    svg {
      color: #6f6f6f;
      font-size: 24px;
      width: 24px;
      height: 24px;
      opacity: 0.7;
    }
    &:hover {
      background: #1e88e533;
      color: #6f6f6f;
    }

    &.${props => props.activeclassname} {
      background-color: #1e88e533;
      span {
        color: #6f6f6f;
      }
    }
  `;

  const CustomSidebarNavListItemTitle = styled(SidebarNavListItemTitle)`
    span {
      color: #6f6f6f;
    }
  `;

  const CustomBadge = styled(Badge)`
    span.MuiChip-label,
    span.MuiChip-label:hover {
      color: #6f6f6f;
    }
  `;

  const sidebarContextTypes: SidebarContextTypes = {
    sidebarNavSection: {
      sidebarNavSectionComponentTheme: {
        Title: CustomSideNavSectionTitle,
      },
    },
    sidebarNavListItem: {
      sidebarNavListItemComponentTheme: {
        Item: CustomItem,
        Title: CustomSidebarNavListItemTitle,
        Badge: CustomBadge,
      },
    },
  };

  return (
    <React.Fragment>
      <SidebarContext.Provider value={sidebarContextTypes}>
        <Card sx={{ borderRadius: '5px', position: 'relative', padding: '5px', overflow: 'auto' }}>
          <CardContent>
            <Box>
              <Typography variant="h6" gutterBottom display="inline">
                User Settings
              </Typography>

              <Divider my={2} />

              <List disablePadding>
                <Items>
                  {userItems &&
                    userItems.map(item => (
                      <SidebarNavSection component="div" key={item.title} pages={item.pages} title={item.title} />
                    ))}
                </Items>
              </List>
            </Box>

            <Box sx={{ mt: 10 }}>
              <Typography variant="h6" gutterBottom display="inline">
                Admin Settings
              </Typography>

              <Divider my={2} />

              <List disablePadding>
                <Items>
                  {adminItems &&
                    adminItems.map(item => (
                      <SidebarNavSection component="div" key={item.title} pages={item.pages} title={item.title} />
                    ))}
                </Items>
              </List>
            </Box>
          </CardContent>
        </Card>
      </SidebarContext.Provider>
    </React.Fragment>
  );
}

export default UserSettings;
