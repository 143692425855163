import React from 'react';
import styled from '@emotion/styled';
import { InputAdornment, TextField as MuiTextField, SxProps, Theme } from '@mui/material';
import { spacing } from '@mui/system';
import { FormikErrors, FormikTouched } from 'formik';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export type FormikTextFieldProps<T> = {
  field: string;
  label?: string | boolean;
  autoFocus?: boolean;
  addornment?: {
    icon: React.ReactNode;
    position: 'start' | 'end';
  };
  touched: FormikTouched<T>;
  values: T;
  errors: FormikErrors<T>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  defaultValue?: String;
  type?: string;
  sx?: SxProps<Theme>;
};

/**
 * A custom form field component for use with Formik.
 *
 * @template T - The type of the form field value.
 * @param {FormikTextFieldProps<T>} props - The props for the FormikTextField component.
 * @returns {JSX.Element} - The rendered FormikTextField component.
 */
export const FormikTextField = <T,>(props: FormikTextFieldProps<T>): JSX.Element => {
  const {
    field,
    label = false,
    autoFocus,
    addornment,
    touched,
    values,
    errors,
    handleBlur,
    handleChange,
    disabled = false,
    onKeyDown,
    defaultValue,
    type = 'text',
    sx: sxProps,
  } = props;

  const defaultTheme: SxProps<Theme> = {
    width: '100%',
    mr: 0,
  };

  const sx = {
    ...defaultTheme,
    ...sxProps,
  };

  return (
    <TextField
      sx={sx}
      type={type}
      name={field}
      label={label}
      defaultValue={defaultValue}
      value={values[field as keyof T]}
      error={Boolean(touched[field as keyof T] && errors[field as keyof T])}
      fullWidth
      onKeyDown={onKeyDown}
      helperText={touched[field as keyof T] && (errors[field as keyof T] as React.ReactNode)}
      onBlur={handleBlur}
      onChange={handleChange}
      variant="outlined"
      autoFocus={autoFocus}
      disabled={disabled}
      InputProps={{
        ...(addornment && {
          endAdornment: <InputAdornment position={addornment.position}>{addornment.icon}</InputAdornment>,
        }),
      }}
      size="small"
    />
  );
};
